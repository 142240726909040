<template>
  <div>
    <webinarStart :webinar="getWebinar()" :user="'attendee'" :username="$tc('message.customer', 1) + ' #' + data.booking_id" />
  </div>
</template>
<script>
export default {
  components: {
    webinarStart () { return import('@/components/webinars/jitsi/start') }
  },
  props: {
    data: Object
  },
  mounted () {
  },
  computed: {

  },
  data () {
    return {
    }
  },
  methods: {
    getWebinar () {
      var send = {
        role: 'attendee',
        ID: this.getMeetingID()
      }
      return send
    },
    getMeetingID () {
      var ID = ''
      ID += this.$route.params.user
      ID += this.data.form
      ID += this.getDate(this.data.begin)
      ID += this.getDate(this.data.end)
      return ID
    },
    getDate (d) {
      d = this.$createDate(d)
      return d.getFullYear() + '' + this.$getNullsBefore(d.getMonth() + 1) + '' + this.$getNullsBefore(d.getDate()) + '' + this.$getNullsBefore(d.getHours()) + '' + this.$getNullsBefore(d.getMinutes())
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
